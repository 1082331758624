* {
    box-sizing: border-box;
  }
  
  body {
    text-align: center;
    padding: 0;
    background: black;
    color: #fff;
    font-family: Open Sans;
  }
  
  h1 {
    font-size: 50px;
    font-weight: 100;
    text-align: center;
  }
  
  body {
    font-family: Open Sans;
    font-weight: 100;
    font-size: 20px;
    color: #fff;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  a {
    color: #fff;
    font-weight: bold;
  }
  a:hover {
    text-decoration: none;
  }
  article {
    display: block;
    width: 700px;
    padding: 50px;
    margin: 0 auto;
  }
  svg {
    width: 75px;
    margin-top: 1em;
  }
  .cls-1 {
    /* fill: #fff; */
  }
  